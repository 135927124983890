<template>
  <v-card-text class="px-4 pt-4">
    <div>
      {{ message }}
    </div>

    <template v-for="(item, itemIndex) in items">
      <div :key="`item_${itemIndex}`">
        {{ format(item.item) }}
        <span> {{ ' (' }} </span>
        <template v-for="(row, rowIndex) in item.rows">
          <span :key="`data_${itemIndex}_row_${rowIndex}`">
            <span v-show="rowIndex > 0">,</span>
            <span>{{ row }}</span>
          </span>
        </template>
        <span>行目</span>
        <span>)</span>
      </div>
    </template>
  </v-card-text>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    format: {
      type: Function,
      required: true,
    },
  },
}
</script>
