<template>
  <input-delete-dialog
    ref="dialog"
    max-width="400"
    :title="$t('message.bulk_delete')"
    :message="$t('message.master_bulk_delete_message')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :delete-text="$t('message.continue')"
    @submit="
      $emit('onClick')
      $refs.dialog.close()
    "
  />
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
  },
}
</script>
