import Snackbar from 'Helpers/snackbar/index'

export function toastErrorOnCheckImport(err) {
  if (err.message === 'invalid_format') {
    Snackbar.error('フォーマットが不適切です')
  } else if (err.message === 'invalid_value') {
    Snackbar.error('不正な値が含まれています。')
  } else {
    Snackbar.error('不明なエラー')
  }
}
