<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.bulk_edit')" />
      <v-card-text>
        <v-form ref="formEdit">
          <slot name="form">
            <v-radio-group v-model="innerInputs.status" row>
              <v-radio color="blue" label="表示" :value="1" />
              <v-radio color="blue" label="非表示" :value="2" />
            </v-radio-group>
          </slot>
          <slot name="additionalForm" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="dialog = false">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" @click="onClick()">
          {{ $t('message.modal_save_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '400',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    innerInputs: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onClick() {
      this.$emit('onClick')
    },
    validate() {
      return this.$refs.formEdit.validate()
    },
    resetValidation() {
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.formEdit.resetValidation()
        })
      }
    },
    reset() {
      this.$refs.formEdit.reset()
    },
  },
}
</script>
