<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title>
        {{ $t('message.save_sort_num') }}
      </v-card-title>
      <v-form ref="form">
        <v-card-text class="px-4 pb-0">
          <div class="pb-5">
            {{ $t('message.save_sort_num_dialog_massage') }}
          </div>
          <v-autocomplete
            v-model="form.order_value"
            :items="items"
            outlined
            dense
            filled
            :rules="formRules"
            item-text="name"
            item-value="value"
          />

          <v-radio-group v-model="form.order_type" class="my-0" row>
            <v-radio color="primary" label="昇順" value="ascending" />
            <v-radio color="primary" label="降順" value="descending" />
          </v-radio-group>
        </v-card-text>

        <v-card-actions class="px-4 pb-4">
          <v-spacer />
          <v-btn @click.native="close">
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn color="primary" @click="onClick"> 並び替え </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    add_items: Array,
  },
  data() {
    return {
      items: [
        { value: 'name', name: '名前で並び替え' },
        { value: 'code', name: 'コードで並び替え' },
      ],
      dialog: false,
      formRules: [(v) => !!v || '※この項目は必須です'],
      form: {
        order_value: 'name',
        order_type: 'ascending',
      },
      loading: false,
    }
  },
  mounted() {},
  methods: {
    open() {
      this.dialog = true
      if (this.add_items) {
        this.items = this.items.concat(this.add_items)
      }
    },
    close() {
      this.dialog = false
      this.loading = false
    },
    onClick() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$emit('onClick')
      }
    },
  },
}
</script>
